/* --------------------------------------------------------------- */

import moment from 'moment';
import _ from 'lodash';
import { useSpring, animated, config as MomentConfig} from 'react-spring';
import { useRef, useEffect, useState, useLayoutEffect, forwardRef, createRef } from 'react';
import LcStack from 'lib/ui/LcStack';
import LcFilter from 'lib/ui/LcFilter';
import { useLcFetchMeetingRoomOverviewData, useLcFetchMeetingRoomAppData } from 'lib/core/LcApi';
import { useLcContext } from 'lib/core/LcContext';

import {
  lcGetCurrentTimeSlotCode,
  lcGetBestMatchingDateIndex,
  mrGetEventDates,
  mrGetAllSlotsForEventDateIdAndRoom,
  mrGetRooms,
  mrGetMeetingDetailsForSlotWithRoom,
  mrGetUpcomingMeetingDetails,
  mrGetAdminEventData,
} from 'lib/core/LcUtils';

import { ReactComponent as ClockIcon } from 'lib/img/clock-icon.svg';
import { ReactComponent as OrganizerIcon } from 'lib/img/organizer-icon.svg';
import { ReactComponent as BookSlotArrowIcon } from 'img/book-slot-arrow-icon.svg';
import { ReactComponent as NextSlots } from 'img/next-slots.svg';
import { ReactComponent as PrevSlots } from 'img/prev-slots.svg';
import { ReactComponent as CalendarIcon } from 'img/calendar-icon.svg';
import { ReactComponent as BackIcon } from 'img/back-icon.svg';

/* --------------------------------------------------------------- */

/* Debug Helper */
function jss(title, data) {
  console.log(title, JSON.stringify(data, null, 2));
}

/* --------------------------------------------------------------- */

function MrMeetingRoomClock(props) {

  const [time_str, setTimeString] = useState(0);

  useEffect(() => {

    let interval_handle = null;
    
    function onTimer() {
      let date = new Date();
      setTimeString(moment(date).format("HH:mm"));
    };
    
    function unsubscribe() {
      if (null != interval_handle) {
        window.clearInterval(interval_handle);
        interval_handle = null;
      }
    };

    interval_handle = window.setInterval(onTimer, 2000);

    onTimer();
    
    return unsubscribe;
  }, []);
  
  return (
    <div className="mr-clock" onClick={props.onClick}>
      {time_str}
    </div>
  );
};

/* --------------------------------------------------------------- */

function MrMeetingRoomSlot(props) {

  const slot = props.slot;

  let classes = ["mr-slot"];
  if (slot.is_booked) {
    classes.push("mr-slot-booked");
  }

  if (props.selected) {
    classes.push("mr-slot-selected");
  }

  const onClick = () => {
    if (props.onClick) {
      props.onClick(props);
    }
  };
  
  return (
    <div
      className={classes.join(" ")}
      onClick={onClick}
    >
      {slot.start_title}
    </div>
  );
};

/* --------------------------------------------------------------- */

/*
  This function will render the horizontal list of slots for a
  Meeting Room. We will add some behavior which allows the use to
  scroll through the meeting slots.
*/
function MrMeetingRoomSlots(props) {

  const slots = props.slots;

  return (
    <>
      <div className="mr-slots-row">
        <div>
          <PrevSlots
            onClick={props.onScrollLeftClicked}
            className="mr-slots-nav-button"
          />
        </div>
        <div className="mr-slots" >
          <animated.div className="mr-slots-scroller" style={props.scrollProps} ref={props.scrollRef}>
          {
            slots.map((slot, dx) => {
              return (<MrMeetingRoomSlot slot={slot} key={dx} onClick={props.onSlotClicked} selected={props.selectedIndex == dx}/>)
            })
          }
          </animated.div>
        </div>
        <div>
          <NextSlots
            className="mr-slots-nav-button"
            onClick={props.onScrollRightClicked}
          />
        </div>
      </div>
    </>
  );
};

/* --------------------------------------------------------------- */

function MrMeetingRoomEventRoom(props) {

  const onClick = (ev) => {
    if (props.onClick) {
      props.onClick(props.room, props.index);
    }
  };

  let is_selected = props.selectedRoomIndex == props.index;
  let classes = ["mr-admin-room"];
  if (is_selected) {
    classes.push("selected");
  }
  
  return (
    <div className={classes.join(" ")} onClick={onClick}>{props.room.title}</div>
  );
};

function MrMeetingRoomEventSelect(props) {

  const onClick = (ev) => {
    if (props.onEventClicked) {
      props.onEventClicked(props.event, props.index);
    }
  };

  let is_selected = props.selectedEventIndex == props.index;
  let classes = ["mr-admin-event"];
  if (is_selected) {
    classes.push("selected");
  }

  return (
    <>
      <div className={classes.join(" ")} onClick={onClick}>{props.event.title}</div>
      {
        props.rooms.map((room, dx) => {

          if (!is_selected) {
            return null;
          }

          return <MrMeetingRoomEventRoom
                   key={dx}
                   index={dx}
                   room={room}
                   onClick={props.onRoomClicked}
                   selectedRoomIndex={props.selectedRoomIndex}
                 />
        })
      }      
    </>
  );
};

function MrMeetingRoomAdmin(props) {

  const [data, error, is_fetching, fetchMeetingRoomAppData] = useLcFetchMeetingRoomAppData();
  const [selected_event_index, setSelectedEventIndex] = useState(null);
  const [selected_room_index, setSelectedRoomIndex] = useState(null);

  useEffect(() => {
    fetchMeetingRoomAppData();
  }, []);

  let events = mrGetAdminEventData(data);

  useEffect(() => {
    
    if (null == selected_event_index) {
      return;
    }

    if (null == selected_room_index) {
      return;
    }

    let info = events[selected_event_index];
    let event = info.event;
    let room = info.rooms[selected_room_index];

    localStorage.setItem("selected_event_id", event.id);
    localStorage.setItem("selected_room_id", room.id);

  }, [selected_event_index, selected_room_index]);

  /* When no room or event has been selected set defaults. */
  let stored_event_id = localStorage.getItem("selected_event_id");
  if (null == selected_event_index
      && null != stored_event_id)
  {
    let dx = events.findIndex((info) => info.event.id == stored_event_id);
    if (dx != -1) {
      setSelectedEventIndex(dx);
    }
  }

  /* When no room has been selected, check if there is one stored in the local storage. */
  let stored_room_id = localStorage.getItem("selected_room_id");
  if (null == selected_room_index
      && null != stored_room_id
      && null != selected_event_index)
  {
    let event = events[selected_event_index];
    let dx = event.rooms.findIndex((room) => room.id == stored_room_id);
    if (dx != -1) {
      setSelectedRoomIndex(dx);
    }
  }

  const onEventClicked = (event, index) => {
    props.onAdminEventIdChange(event.id);
    props.onAdminRoomIdChange(null);
    setSelectedEventIndex(index);
    setSelectedRoomIndex(null);
  };

  const onRoomClicked = (room, index) => {
    props.onAdminRoomIdChange(room.id);
    setSelectedRoomIndex(index);
  };

  return (
    <div className="mr-admin">
    {
      events.map((info, dx) => {
        return <MrMeetingRoomEventSelect
                 key={dx}
                 index={dx}
                 event={info.event}
                 rooms={info.rooms}
                 selectedEventIndex={selected_event_index}
                 selectedRoomIndex={selected_room_index}
                 onEventClicked={onEventClicked}
                 onRoomClicked={onRoomClicked}
               />
      })
    }
      <div className="mr-admin-close" onClick={props.onAdminCloseClicked}>Close</div>
    </div>
  );
};

/* --------------------------------------------------------------- */

function MrMeetingRoomScreen() {

  // console.log("@todo on timeout, hide filter when someone opened it.");
  // console.log("@todo when no slot has been selected select the one of the current time.");
  // console.log("@todo last time slot should be added to row.");
  // console.log("@todo check if date time formats are correct; e.g. filter + detail screen seem to be different. ");
  const toggle_counter_threshold = 7;
  const ctx = useLcContext();
  const [data, error, is_fetching, fetchMeetingRoomOverviewData ] = useLcFetchMeetingRoomOverviewData();  
  const [selected_date_index, setSelectedDateIndex] = useState(null);
  const [selected_room_index, setSelectedRoomIndex] = useState(null);
  const [selected_slot_index, setSelectedSlotIndex] = useState(null);
  const [user_selected_slot, setUserSelectedSlot] = useState(false); /* This used to indicate a user selected a slot, or we automatically selected one e.g. via a timeout */
  const [idle_timeout, setIdleTimeoutInMillis] = useState(Date.now() + 1000);
  const [curr_time, setCurrentTimeInMillis] = useState(Date.now());
  const [scroll_change, setScrollChange] = useState(750); /* How much we move when we press next/previous. */
  const [scroll_pos, setScrollPosition] = useState(0);
  const [scroll_counter, setScrollToCurrentTimeCounter] = useState(0);
  const [admin_toggle_counter, setAdminToggleCounter] = useState(0); /* We have a hidden button (currently the clock on the right) which can toggle a tiny admin which allows use to set the room for of the ipad.) */
  const scroll_ref = useRef(null);
  const scroll_props = useSpring({to: { left: scroll_pos}, config: {...MomentConfig.default, duration: 350}});

  /* --------------------------------------------------------------- */
  /* Fetch data for the meeting.                                     */
  /* --------------------------------------------------------------- */
  
  useEffect(() => {

    let stored_event_id = localStorage.getItem("selected_event_id");
    if (null == stored_event_id) {
      setAdminToggleCounter(toggle_counter_threshold); 
      return;
    }

    if (stored_event_id != ctx.state.event_id) {
      ctx.dispatch(ctx.actions.setEventId(stored_event_id));
    }
      
  }, []);

  useEffect(() => {

    /* Fetch the data again after ####-millis. */
    const handle = window.setInterval(() => {
      if (null != ctx.state.event_id) {
        fetchMeetingRoomOverviewData();
      }
    }, 20000);

    /* Initial fetch */
    if (null != ctx.state.event_id) {
      fetchMeetingRoomOverviewData();
    }

    return () => window.clearInterval(handle);
    
  },[ctx.state.event_id]);

  /* --------------------------------------------------------------- */
  /* Extract data and get selected ones                              */
  /* --------------------------------------------------------------- */
  
  /* Get rooms, and the selected one. */
  let rooms = mrGetRooms(data);
  let selected_room = null;
  if (null != selected_room_index) {
    selected_room = rooms[selected_room_index];
  }

  /* Get event dates and the selected one. */
  let dates = mrGetEventDates(data);
  let selected_date = null;
  if (null != selected_date_index) {
    selected_date = dates[selected_date_index];
  }

  /* Get the slots (only done when date and room are selected. */
  let slots = mrGetAllSlotsForEventDateIdAndRoom(data, selected_date?.id, selected_room?.id);
  let selected_slot = null;
  if (null != selected_slot_index) {
    selected_slot = slots[selected_slot_index];
  }

  /* --------------------------------------------------------------- */
  /* Set defaults                                                    */
  /* --------------------------------------------------------------- */
  
  /* Set the default date index. */
  if (null == selected_date_index
      && dates.length > 0)
  {
    let tmp = dates.map((d) => d.date.date);
    let dx = lcGetBestMatchingDateIndex(tmp);
    if (dx >= 0 && dx < dates.length) {
      setSelectedDateIndex(dx);
    }
  }

  /* Set the default room index */
  if (null == selected_room_index
      && rooms.length > 0)
  {
    let stored_room_id = localStorage.getItem("selected_room_id");
    if (null != stored_room_id) {
      let dx = rooms.findIndex((room) => room.id == stored_room_id);
      if (dx != -1) {
        setSelectedRoomIndex(dx);
      }
    }
    else {
      /* This will trigger the admin popup when no room has been selected yet. */
      if (admin_toggle_counter != toggle_counter_threshold) {
        setAdminToggleCounter(toggle_counter_threshold);
      }
    }
  }

  /* 
    Set the default slot index. 
    
    This is not 100% required as we will scroll to the current
    time slot when the first timeout (after opening this page),
    triggers. Though, this snippet makes sure that we see
    something directly when we load the page.
  */
  if (null == selected_slot_index
      && slots.length > 0)
  {
    let curr_slot_code = lcGetCurrentTimeSlotCode();
    let curr_slot = slots.find((slot) => slot.start == curr_slot_code);
    if (!curr_slot) {
      curr_slot = _.last(slots);
    }

    setSelectedSlotIndex(curr_slot.index);
  }

  let upcoming_meeting = null;
  if (data
      && slots
      && selected_room
      && dates
      && null != selected_date_index
      && false == user_selected_slot /* The client only wants to show the next meeting in idle state. */
     )
  {
    /* 
      Make sure that  we only show the upcoming  meeting when the
      current date has been selected. 
    */
    let item = dates[selected_date_index];
    let today = moment().format("YYYYMMDD");
    if (today == item.date.date) {
      upcoming_meeting = mrGetUpcomingMeetingDetails(data, slots, selected_room, item.date.id);
    }
  }

  /* --------------------------------------------------------------- */
  /* IDLE TIMEOUT                                                    */
  /* --------------------------------------------------------------- */

  const scrollSlotsToCurrentTime = () => {

    if (!slots || 0 == slots.length) {
      return;
    }
    
    let curr_slot_code = lcGetCurrentTimeSlotCode();
    let curr_slot = slots.find((slot) => slot.start == curr_slot_code);

    if (!curr_slot) {
      /* When we don't find the slot we scroll to the last .. could be something else ... */
      curr_slot = _.last(slots);
    }

    scrollToSlotIndex(curr_slot.index);
    setSelectedSlotIndex(curr_slot.index);
  };

  const scrollToSlotIndex = (index) => {

    if (!slots || index > slots.length) {
      return;
    }
    
    let scroll_x = 138 * index;
    let max_scroll = scroll_ref.current.scrollWidth - scroll_ref.current.offsetWidth;

    if (scroll_x > max_scroll) {
      scroll_x = max_scroll;
    }

    setScrollPosition(-scroll_x);
  };

  /* We have to make sure that the elements have been created; otherwise the scroll widths might hold invalid values. */
  useEffect(() => {
    scrollSlotsToCurrentTime();
  }, [scroll_counter]);
  
  /* Set timer ... triggers the effect below. */
  useEffect(() => {
    
    let handle = null;

    const reset = () => {
      if (null != handle) {
        window.clearInterval(handle);
        handle = null;
      }
    };

    const onInterval = () => {
      setCurrentTimeInMillis(Date.now());
    }
    
    handle = window.setInterval(onInterval, 5000);
    
    return () => reset();
    
  }, []);

  /* 
    This effect checks if we reached a timeout; if so we scroll to
    the current time and reset all the necessary state.
  */
  useEffect(() => {

    /* When there are no slots, return directly. */
    if (!slots || 0 == slots.length) {
      return;
    }
    
    let now = Date.now();
    if (now < idle_timeout) {
      return;
    }

    /* Set default room index. */
    let stored_room_id = localStorage.getItem("selected_room_id");
    if (null != stored_room_id
        && rooms)
    {
      let dx = rooms.findIndex((room) => room.id == stored_room_id);
      if (dx != -1) {
        setSelectedRoomIndex(dx);
      }
    }

    /* Set default date index again. */
    if (dates && dates.length > 0) {
      let tmp = dates.map((d) => d.date.date);
      let dx = lcGetBestMatchingDateIndex(tmp);
      setSelectedDateIndex(dx);
    }

    /* 
      Increment timeout again, scroll to current time and disable
      user selection. We also reset the admin counter.
    */
    incrementIdleTimeout();
    setUserSelectedSlot(false);
    setAdminToggleCounter(0);

    /* Scroll to the curent time. */
    scrollToCurrentTime();
    
  }, [curr_time]);

  /* --------------------------------------------------------------- */
  /* Handle events + Helpers                                         */
  /* --------------------------------------------------------------- */

  const incrementIdleTimeout = () => {
    let new_timeout = Date.now() + 30000;
    setIdleTimeoutInMillis(new_timeout);
  };

  const forceIdleTimeout = () => {
    setIdleTimeoutInMillis(Date.now() - 1000);
    setCurrentTimeInMillis(curr_time - 1);
  };

  /* Scroll to the current time slot, or the last one when we're past the last slot. */
  const scrollToCurrentTime = () => {
    setScrollToCurrentTimeCounter(scroll_counter + 1);    
  };
  
  const onDateFilterChange = (info) => {
    
    incrementIdleTimeout();
    setSelectedDateIndex(info.index);

    /*
      When we change dates we will automatically scroll to the
      current time as it may happen that the currently selected
      slot isn't available anymore.
     */
    scrollToCurrentTime();

    /* 
      When the user switches between dates we have to unset the
      selected slot index, because the selected date might not
      have the slot. When the slot index is `null` we will select
      the best index we can, see above `Set defaults`.
    */
    setSelectedSlotIndex(null);
  };

  const onRoomFilterChange = (info) => {
    incrementIdleTimeout();
    setSelectedRoomIndex(info.index);
  };

  const onSlotClicked = (info) => {
    incrementIdleTimeout();
    setUserSelectedSlot(true);
    setSelectedSlotIndex(info.slot.index);
  };

  const onBackToNowClicked = (ev) => {
    incrementIdleTimeout();
    setUserSelectedSlot(false);
    setSelectedSlotIndex(null);
  };

  const onScrollLeftClicked = (ev) => {
    incrementIdleTimeout();
    setScrollPosition(Math.min(scroll_pos + scroll_change, 0));
  }

  const onScrollRightClicked = (ev) => {
    incrementIdleTimeout();
    let max = scroll_ref.current.scrollWidth - scroll_ref.current.offsetWidth;
    setScrollPosition(Math.max(scroll_pos - scroll_change, -max));
  }

  const onHiddenAdminToggleButtonClicked = (ev) => {
    setAdminToggleCounter(admin_toggle_counter + 1);
  }

  const onAdminCloseClicked = (ev) => {
    setAdminToggleCounter(0);
  }

  const onAdminEventIdChange = (id) => {
    ctx.dispatch(ctx.actions.setEventId(id));
  }

  const onAdminRoomIdChange = (id) => {

    if (!rooms) {
      console.error("No rooms yet.");
      setSelectedRoomIndex(null);
      return;
    }
    
    let dx = rooms.findIndex((room) => room.id == id);
    if (-1 == dx) {
      setSelectedRoomIndex(null);
      return;
    }

    setSelectedRoomIndex(dx);
  }

  const onUpcomingMeetingClicked = () => {

    if (!upcoming_meeting
        || !upcoming_meeting.slot
        || upcoming_meeting.slot.index > slots.length
       )
    {
      console.error("Invalid upcoming meeting.");
      return;
    }
      
    setSelectedSlotIndex(upcoming_meeting.slot.index);
    scrollToSlotIndex(upcoming_meeting.slot.index);
    setUserSelectedSlot(true);
    incrementIdleTimeout();
  }

  /* --------------------------------------------------------------- */
  /* Rendering                                                       */
  /* --------------------------------------------------------------- */

  const getMeetingDetails = () => {

    if (!selected_slot) {
      return null;
    }
    
    if (!selected_room) {
      return null;
    }
    
    let slot = selected_slot;
    let details = mrGetMeetingDetailsForSlotWithRoom(data, slots, selected_slot, selected_room);

    /* Gets the details when we have a meeting for this slot. */
    const getBookedDetails = () => {
      return (
        <>
          <div className="mr-meeting-date">
            <CalendarIcon className="mr-meeting-icon"/>
            {details.date || ''}
          </div>
          <div className="mr-meeting-specs">
            <div className="mr-meeting-icon"><ClockIcon /> </div>
            <div className="mr-meeting-spec">{details.duration}</div>
            <div className="mr-meeting-icon mr-meeting-spec-organizer"><OrganizerIcon /></div>
            <div className="mr-meeting-spec">Booked by <strong>{details.meeting.bookedBy}</strong></div>
          </div>
          <div className="mr-meeting-desc">{details.meeting.description}</div>
        </>
      )
    };

    const getDefaultDetails = () => {
      return (
        <>
          <div className="mr-meeting-specs">
            <div className="mr-meeting-icon"><ClockIcon /> </div>
            <div className="mr-meeting-spec">{details.duration}</div>
          </div>
          <div className="mr-meeting-desc">Available</div>
        </>
      );
    }

    let classes = ["mr-meeting"];
    if (selected_slot.meeting) {
      classes.push("is-booked");
    }
    else {
      classes.push("is-not-booked");
    }
                   
    return (
      <>
        <div className={classes.join(" ")}>
          <div className="mr-meeting-back-to-now" onClick={onBackToNowClicked}>
            <BackIcon className="mr-meeting-icon" />
            Back to now
          </div>
          <h2>{details.room.title}</h2>
          { details.meeting && getBookedDetails() }
          { !details.meeting && getDefaultDetails() }
      </div>
      </>
    );
  };

  let room_classes = ["mr-room"];
  if (user_selected_slot) {
    room_classes.push("selected");
  }

  let show_admin = (admin_toggle_counter >= 7) ? true : false;
  
  return (
    <div className="mr-app">
      <div className={room_classes.join(" ")}>
        <div className="mr-room-details">
          {
            true == show_admin &&
            <MrMeetingRoomAdmin
              onAdminCloseClicked={onAdminCloseClicked}
              onAdminEventIdChange={onAdminEventIdChange}
              onAdminRoomIdChange={onAdminRoomIdChange}
            />
          }
          {
            false == show_admin &&
              getMeetingDetails()
          }
          <MrMeetingRoomClock onClick={onHiddenAdminToggleButtonClicked} />
        </div>
        <div className="mr-upcoming-meeting" onClick={onUpcomingMeetingClicked}>
        {
          upcoming_meeting &&
            <>
              <strong className="mr-upcoming-title">Next meeting</strong>
              <div className="mr-meeting-icon"><ClockIcon /></div>
              <span className="mr-upcoming-duration">{upcoming_meeting.duration}</span>
              <div className="mr-meeting-icon"><OrganizerIcon /> </div>
              <span className="mr-upcoming-bookedby">Booked by &nbsp;</span>
              <strong className="mr-upcoming-organizer">{upcoming_meeting.meeting.bookedBy}</strong>
          </>
        }
      </div>
      </div>
      <div className="mr-slots-container">
        <div className="mr-slots-interaction">
          <span className="mr-filter-title">Schedule for </span>
          <LcFilter
            items={dates}
            direction="up"
            selectedIndex={selected_date_index}
            onChange={onDateFilterChange}
          />
          <LcFilter
            items={rooms}
            direction="up"
            selectedIndex={selected_room_index}
            onChange={onRoomFilterChange}
          />
          <div className="mr-slots-book-at">
            <BookSlotArrowIcon className="mr-slots-book-at-icon"/>
            Book your slots at the reception
          </div>
        </div>
        <MrMeetingRoomSlots
          slots={slots}
          scrollRef={scroll_ref}
          scrollProps={scroll_props}
          onSlotClicked={onSlotClicked}
          onScrollLeftClicked={onScrollLeftClicked}
          onScrollRightClicked={onScrollRightClicked}
          selectedIndex={selected_slot_index}
        />
      </div>
    </div>
  );
};

/* --------------------------------------------------------------- */

export default MrMeetingRoomScreen;

/* --------------------------------------------------------------- */
