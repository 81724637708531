/* --------------------------------------------------------------- */

import { LcContextProvider } from 'lib/core/LcContext';
import MrMeetingRoomScreen from 'screens/MrMeetingRoomScreen';
import "css/meetingroom.css"
import "lib/css/lib.css"

/* --------------------------------------------------------------- */

function App() {
  return (
    <LcContextProvider>
      <MrMeetingRoomScreen />
    </LcContextProvider>
  );
}

/* --------------------------------------------------------------- */

export default App;

/* --------------------------------------------------------------- */
